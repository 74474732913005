import React from "react";
import { NumberInput, required } from "react-admin";
import PropTypes from "prop-types";

const ToolModelValueInput = ({ source }) => {
  return (
    <div>
      <NumberInput
        source={`${source}.min`}
        label={`supermin ${source}`}
        validate={[required()]}
      />
      <NumberInput
        source={`${source}.min_certified`}
        label={`min ${source}`}
        validate={[required()]}
      />
      <NumberInput
        source={`${source}.alert`}
        label={`alert ${source}`}
        validate={[required()]}
      />
      <NumberInput
        source={`${source}.max`}
        label={`max ${source}`}
        validate={[required()]}
      />
    </div>
  );
};

ToolModelValueInput.propTypes = {
  source: PropTypes.string.isRequired,
};
export default ToolModelValueInput;
