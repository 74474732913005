import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import React from "react";
import jsonExport from "jsonexport/dist";
import { ReferenceField, downloadCSV, TextInput } from "react-admin";
import CustomPagination from "../util_components/customPagination";
import ExportButton from "../util_components/exportButton";
import ListActionsExtend from "../util_components/ListActionsExtend";
import { FilterGuesserExtend } from "../util_components/FilterGuesserExtend";

const LicenceList = (props) => (
  <ListGuesser
    actions={
      <ListActionsExtend
        actionsToAdd={[
          {
            name: "exportButton",
            component: <ExportButton resource="licences" />,
          },
        ]}
      />
    }
    exporter={false}
    perPage={50}
    pagination={<CustomPagination {...props} />}
    filters={
      <FilterGuesserExtend
        filterstoadd={[
          {
            name: "toolUuid",
            component: <TextInput source={"toolUuid"} key="toolUuid" />,
          },
          {
            name: "serialNumber",
            component: <TextInput source={"serialNumber"} key="serialNumber" />,
          },
          {
            name: "partNumber",
            component: <TextInput source={"partNumber"} key="partNumber" />,
          },
        ]}
        {...props}
      />
    }
    {...props}
  >
    <FieldGuesser source={"serialNumber"} />
    <FieldGuesser source={"toolUuid"} />
    <ReferenceField
      label="Licence level"
      source="partNumber"
      reference="admin/part_numbers"
      sortBy="partNumber.licenceLevel"
    >
      <FieldGuesser source="licenceLevel" />
    </ReferenceField>
    <ReferenceField
      source="partNumber"
      reference="admin/part_numbers"
      sortBy="partNumber.partNumberId"
    >
      <FieldGuesser source="partNumberId" />
    </ReferenceField>
    <ReferenceField
      label="Part number label"
      source="partNumber"
      reference="admin/part_numbers"
      sortBy="partNumber.libelle"
    >
      <FieldGuesser source="libelle" />
    </ReferenceField>
    <ReferenceField
      label="is spare part ?"
      source="partNumber"
      reference="admin/part_numbers"
      sortBy="partNumber.sparePart"
    >
      <FieldGuesser source="sparePart" />
    </ReferenceField>
    <FieldGuesser source={"createdAt"} />
    <FieldGuesser source={"updatedAt"} />
  </ListGuesser>
);

export default LicenceList;
