import { ShowGuesser } from "@api-platform/admin";
import React from "react";
import { DateField, ReferenceField, TextField } from "react-admin";

const LicenceShow = (props) => (
  <ShowGuesser {...props}>
    <TextField source={"serialNumber"} />
    <TextField source={"toolUuid"} />
    <ReferenceField
      label="Licence level"
      source="partNumber"
      reference="admin/part_numbers"
    >
      <TextField source={"licenceLevel"} />
    </ReferenceField>
    <ReferenceField source="partNumber" reference="admin/part_numbers">
      <TextField source="partNumberId" />
    </ReferenceField>
    <ReferenceField
      label="Part number label"
      source="partNumber"
      reference="admin/part_numbers"
    >
      <TextField source="libelle" />
    </ReferenceField>
    <ReferenceField
      label="is spare part ?"
      source="partNumber"
      reference="admin/part_numbers"
    >
      <TextField source="sparePart" />
    </ReferenceField>
    <DateField source={"createdAt"} />
    <DateField source={"updatedAt"} />
  </ShowGuesser>
);

export default LicenceShow;
